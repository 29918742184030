import { Box } from "@mui/material";
import { Fragment, type ReactNode } from "react";

interface DotSeparatedListProps {
  children: ReactNode[];
  /**
   * Forces the children to be inline so they should nicely wrap over multiple lines.
   */
  inline?: boolean;
}

/**
 * Renders a list of children separated by a dot.
 */
export function DotSeparatedList(props: DotSeparatedListProps) {
  const { children, inline = true } = props;

  const filteredChildren = children.filter(Boolean);

  return (
    <Box
      component="span"
      sx={{
        display: "inline-block",

        "> *": {
          verticalAlign: "middle",
        },

        ...(inline && {
          "> *:not(.dot-separator)": {
            display: "inline",
          },
        }),

        ...(!inline && {
          display: "inline-flex",
          alignItems: "center",
        }),
      }}
    >
      {filteredChildren.map((child, index) => (
        <Fragment
          key={
            typeof child === "string" || typeof child === "number"
              ? child.toString()
              : `dot-element-${index}`
          }
        >
          {child}
          {index < filteredChildren.length - 1 && (
            <Box
              component="span"
              className="dot-separator"
              sx={{
                display: "inline-block",
                width: "0.125rem",
                height: "0.125rem",
                borderRadius: "50%",
                backgroundColor: "currentColor",
                flexShrink: 0,
                marginInline: 2,
              }}
            />
          )}
        </Fragment>
      ))}
    </Box>
  );
}
