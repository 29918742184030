import { Badge } from "@mui/material";
import { useFacilityPhotos } from "@src/appV2/Facilities/api/useFacilityPhotos";

import type { AvatarSize } from "../theming/overrides/avatar";
import type { IconButtonSize } from "../theming/overrides/iconButton";
import { Avatar, iconSizeByAvatarSize } from "./Avatar";
import { AvatarSkeleton } from "./AvatarSkeleton";
import { CbhIcon } from "./CbhIcon";

interface FacilityAvatarProps {
  facilityId: string;
  isFavorite?: boolean;
  size?: AvatarSize;
}

const badgeSizeByAvatarSize: Record<AvatarSize, IconButtonSize> = {
  small: "xSmall",
  medium: "small",
  large: "small",
} as const;

export function FacilityAvatar(props: FacilityAvatarProps) {
  const { facilityId, isFavorite, size = "medium" } = props;

  const { data: facilityPhotos, isLoading: isFacilityPhotosLoading } = useFacilityPhotos({
    facilityId,
  });

  if (isFacilityPhotosLoading) {
    return <AvatarSkeleton size={size} />;
  }

  const photoUrl = facilityPhotos?.photosList[0]?.url;

  if (isFavorite) {
    const badgeSize = badgeSizeByAvatarSize[size];
    return (
      <Badge
        badgeContent={<CbhIcon type="like-filled" size={badgeSize} />}
        overlap="circular"
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        sx={(theme) => ({
          "& .MuiBadge-badge": {
            padding: 0,
            width: theme.iconButtonSize?.[badgeSize],
            height: theme.iconButtonSize?.[badgeSize],
            boxShadow: theme.shadows[2],
            backgroundColor: theme.palette.background.tertiary,
            borderColor: theme.palette.border?.subtle,
            borderWidth: theme.borderWidth?.thin,
            borderStyle: "solid",
            borderRadius: "50%",
          },
        })}
      >
        <Avatar src={photoUrl} size={size}>
          <CbhIcon type="building" size={iconSizeByAvatarSize[size]} />
        </Avatar>
      </Badge>
    );
  }

  return (
    <Avatar src={photoUrl} size={size}>
      <CbhIcon type="building" size={iconSizeByAvatarSize[size]} />
    </Avatar>
  );
}
