import { ButtonBase, type ButtonBaseProps } from "@mui/material";

import { type IconType } from "./CbhIcon";
import { IconWithCount } from "./IconWithCount";

// TODO: If we need to add more sizes, we can add them here. At the time of writing this, there are no designs for this.
interface IconWithCountButtonProps extends Pick<ButtonBaseProps, "onClick"> {
  count: number;
  isActive: boolean;
  baseIconType: IconType;
  activeIconType?: IconType;
}

export function IconWithCountButton(props: IconWithCountButtonProps) {
  const { isActive, count, baseIconType, activeIconType = baseIconType, onClick } = props;

  return (
    <ButtonBase
      aria-pressed={isActive}
      sx={(theme) => ({
        color: theme.palette.text.tertiary,
        transition: theme.transitions.create(["color"], {
          duration: theme.transitions.duration.standard,
        }),

        "&[aria-pressed=true]": {
          color: theme.palette.text.primary,
        },

        "@media (hover: hover)": {
          "&:hover, &:focus": {
            color: theme.palette.text.primary,
          },
        },

        "@media (hover: none)": {
          "&:active": {
            color: theme.palette.text.primary,
          },
        },
      })}
      onClick={onClick}
    >
      <IconWithCount
        iconType={isActive ? activeIconType : baseIconType}
        color={() => "inherit"}
        count={count}
      />
    </ButtonBase>
  );
}
