import { Text } from "@clipboard-health/ui-react";
import { Skeleton } from "@mui/material";
import { type ComponentProps } from "react";

// TODO: Export TextProps from @clipboard-health/ui-react
interface DistanceTextProps extends Pick<ComponentProps<typeof Text>, "variant" | "color"> {
  formattedDistance?: string;
  isLoading: boolean;
  isError: boolean;
  skeletonWidth?: string;
}

export function DistanceText(props: DistanceTextProps) {
  const {
    formattedDistance,
    isLoading,
    isError,
    variant = "caption",
    color,
    skeletonWidth = "15ch",
  } = props;

  if (isLoading) {
    return (
      <Skeleton
        variant="text"
        sx={{
          width: skeletonWidth,
          fontSize: (theme) => theme.typography[variant].fontSize,
          lineHeight: (theme) => theme.typography[variant].lineHeight,
        }}
      />
    );
  }

  /**
   * Hide distance errors silently since:
   * 1. Distance info is non-critical
   * 2. Showing errors could negatively impact user experience
   * 3. Users can still proceed without this information
   */
  if (isError) {
    return null;
  }

  return (
    <Text variant={variant} color={color}>
      {formattedDistance}
    </Text>
  );
}
