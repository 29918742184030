import { mergeSxProps } from "@clipboard-health/ui-react";
import { Stack, type StackProps } from "@mui/material";
import { type ReactNode } from "react";

interface HorizontalScrollViewProps extends Omit<StackProps, "children"> {
  children: ReactNode[] | ReactNode;
  stretch?: boolean;
  /**
   * As this component often renders inside padded containers and we want it to look like it overflows the container
   *  while scrolling, we need to compensate for the outer padding. This property will set negative x margin with given
   * value and inner padding with same value
   *
   * @example
   * <CardContent sx={{ padding: 5 }}>
   *   <HorizontalScrollView outerPaddingCompensation={5}>
   *     <ScrollContent />
   *   </HorizontalScrollView>
   * </CardContent>
   */
  outerPaddingCompensation?: number;
}

export function HorizontalScrollView(props: HorizontalScrollViewProps) {
  const { children, sx, stretch = false, outerPaddingCompensation, ...restProps } = props;

  return (
    <Stack
      direction="row"
      spacing={3}
      sx={mergeSxProps(
        (theme) => ({
          overflowX: "auto",
          scrollbarWidth: "none",
          ...(outerPaddingCompensation && {
            mx: `calc(-1 * ${theme.spacing(outerPaddingCompensation)})`,
            px: `${theme.spacing(outerPaddingCompensation)}`,
          }),

          "&::-webkit-scrollbar": {
            display: "none",
          },

          "&:empty": {
            display: "none",
          },

          "& > *": {
            flexShrink: 0,
            width: stretch ? "90%" : undefined,
            maxWidth: "90%",
          },

          "& > :only-child": {
            width: stretch ? "100%" : undefined,
            maxWidth: "100%",
          },
        }),
        sx
      )}
      {...restProps}
    >
      {children}
    </Stack>
  );
}
