import { mergeSxProps } from "@clipboard-health/ui-react";
import { OutlinedInput, type OutlinedInputProps } from "@mui/material";

import { CbhIcon, type IconType } from "./CbhIcon";

export interface InputProps
  extends Omit<OutlinedInputProps, "startAdornment" | "endAdornment" | "multiline"> {
  iconType?: IconType;
}

// TODO: Add inputs from MUI to eslint ignore pattern after we roll out the redesign
// MUI inputs to ignore: TextField, OutlinedInput, FilledInput, InputBase
export function Input(props: InputProps) {
  const { iconType, sx, ...rest } = props;
  return (
    <OutlinedInput
      {...rest}
      startAdornment={iconType ? <CbhIcon type={iconType} size="small" /> : undefined}
      sx={mergeSxProps(
        (theme) => ({
          boxShadow: props.value && !props.disabled ? theme.shadows[2] : undefined,
        }),
        sx
      )}
    />
  );
}
