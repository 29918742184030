import {
  ListItemIcon as MuiListItemIcon,
  type ListItemIconProps as MuiListItemIconProps,
  type Theme,
} from "@mui/material";

import { CbhIcon, type IconType } from "../CbhIcon";

export interface ListItemIconProps extends Omit<MuiListItemIconProps, "children" | "color"> {
  iconType: IconType;
  color?: (theme: Theme) => string;
}

export function ListItemIcon(props: ListItemIconProps) {
  const { iconType, color, ...restProps } = props;

  return (
    // styling is done via styleOverrides in theme
    <MuiListItemIcon {...restProps}>
      <CbhIcon type={iconType} color={color} size="medium" />
    </MuiListItemIcon>
  );
}
