import { Text } from "@clipboard-health/ui-react";
import { type ReactNode } from "react";

interface InformationSectionTextContentProps {
  children: ReactNode;
}

export function InformationSectionTextContent({ children }: InformationSectionTextContentProps) {
  return (
    <Text variant="body2" color={(theme) => theme.palette.text.secondary}>
      {children}
    </Text>
  );
}
