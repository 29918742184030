import { mergeSxProps } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import { Stack, type SxProps, type Theme } from "@mui/material";

import { getSafeAreaPaddings } from "../utils/getSafeAreaPaddings";
import { IconButton } from "./IconButton";

interface DialogFooterProps {
  orientation: "horizontal" | "vertical";
  withShadow?: boolean;
  children?: React.ReactNode;
  onClose?: React.MouseEventHandler<HTMLButtonElement>;
  invert?: boolean;
  sx?: SxProps<Theme>;
  backgroundColor?: (theme: Theme) => string | undefined;
}

export function DialogFooter(props: DialogFooterProps) {
  const {
    children,
    onClose,
    orientation = "horizontal",
    sx,
    invert,
    withShadow = false,
    backgroundColor,
  } = props;

  const isVertical = orientation === "vertical";

  return (
    <Stack
      direction={isVertical ? "column" : "row"}
      alignItems="center"
      justifyContent="space-between"
      gap={4}
      sx={mergeSxProps(
        (theme) => ({
          px: 5,
          paddingTop: 5,
          boxShadow: withShadow ? theme.shadows[4] : "none",
          ...getSafeAreaPaddings({
            bottom: true,
            fallbackBottom: theme.spacing(5),
          }),
          backgroundColor: isDefined(backgroundColor) ? backgroundColor(theme) : "transparent",
          // This is to ensure that the footer is always on top of underlying page and its shadow renders correctly on top of the page
          zIndex: 1,

          "& > *:only-child": {
            margin: "auto",
          },
        }),
        sx
      )}
    >
      {isVertical && children}

      {isDefined(onClose) && (
        <IconButton
          invert={invert ?? false}
          size="large"
          variant="outlined"
          iconType="close"
          onClick={(event) => {
            event.stopPropagation();
            onClose(event);
          }}
        />
      )}

      {!isVertical && children}
    </Stack>
  );
}
