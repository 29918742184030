import {
  ListItemButton as MuiListItemButton,
  type ListItemButtonProps as MuiListItemButtonProps,
} from "@mui/material";

import { CallToActionIcon } from "../CallToActionIcon";

interface ListItemButtonProps extends MuiListItemButtonProps {
  dense?: boolean;
}

export function ListItemButton(props: ListItemButtonProps) {
  const { dense, children, ...restProps } = props;

  return (
    <MuiListItemButton dense={dense} {...restProps}>
      {children}

      <CallToActionIcon sx={{ marginLeft: 3, alignSelf: "flex-start" }} />
    </MuiListItemButton>
  );
}
