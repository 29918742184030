import {
  IconButton as MuiIconButton,
  type IconButtonProps as MuiIconButtonProps,
} from "@mui/material";

import type { IconButtonSize, IconButtonVariant } from "../theming/overrides/iconButton";
import { ButtonInternalLink } from "./ButtonInternalLink";
import { CbhIcon, type IconType } from "./CbhIcon";

interface IconButtonProps extends Pick<MuiIconButtonProps, "disabled" | "onClick"> {
  variant: IconButtonVariant;
  iconType: IconType;
  invert?: boolean;
  size?: IconButtonSize;
  // for some reason, MUI's IconButton props doesn't include href. However, when passed down to the component, it works as expected
  href?: string;
}

const iconButtonVariantOverrides: Record<IconButtonVariant, MuiIconButtonProps["color"]> = {
  contained: "primary",
  outlined: "secondary",
};

/**
 * TODO: Add IconButton from MUI to eslint ignore pattern after we roll out the redesign
 *
 * A wrapper around MuiIconButton that limits variants to our custom ones and adds our custom icon handling
 * NOTE: Provide `href` to make the button render as an anchor tag. It will support react-router redirecs
 */

export function IconButton(props: IconButtonProps) {
  const { variant = "contained", iconType, size = "medium", onClick, invert, ...restProps } = props;

  return (
    <MuiIconButton
      invert={invert}
      color={iconButtonVariantOverrides[variant]}
      size={size}
      LinkComponent={ButtonInternalLink}
      onClick={onClick}
      {...restProps}
    >
      <CbhIcon type={iconType} />
    </MuiIconButton>
  );
}
