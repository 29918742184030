import { mergeSxProps } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import { type SxProps, type Theme } from "@mui/material";
import { useHistory } from "react-router-dom";

import { IconButton } from "./IconButton";
import { PageHeaderWrapper } from "./PageHeaderWrapper";

interface DialogHeaderProps {
  onBack?: React.MouseEventHandler<HTMLButtonElement>;
  sx?: SxProps<Theme>;
}

/**
 * Predefined header for dialogs. It has a default back button that goes back in the history stack.
 * For dialogs that don't stack, we rather use DialogFooter that has the 'close' CTA
 */
export function DialogHeader(props: DialogHeaderProps) {
  const { onBack, sx } = props;

  const history = useHistory();

  return (
    <PageHeaderWrapper
      component="div"
      // TODO: Metalab will change how our headers look like so for now just hardcoding transparent and we will see what's next
      sx={mergeSxProps(
        {
          top: 0,
          paddingBottom: 8,
          background: "transparent",
          position: "sticky",
          zIndex: (theme) => theme.zIndex.modal,
        },
        sx
      )}
    >
      <IconButton
        iconType="arrow-left"
        variant="outlined"
        size="large"
        onClick={(event) => {
          if (isDefined(onBack)) {
            onBack(event);
          } else {
            history.goBack();
          }
        }}
      />
    </PageHeaderWrapper>
  );
}
