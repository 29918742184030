import { mergeSxProps, Text } from "@clipboard-health/ui-react";
import { Stack, type SxProps, type Theme } from "@mui/material";

interface CounterTextProps {
  count: number;
  children: React.ReactNode;
  invisible?: boolean;
  max?: number;
  showZero?: boolean;
  suffix?: string;
  sx?: SxProps<Theme>;
}

export function CounterText(props: CounterTextProps) {
  const { count, suffix, children, max = 99, showZero, invisible, sx } = props;

  const isHidden = (count === 0 && !showZero) || invisible;

  return (
    <Stack direction="row" alignItems="center" spacing={4}>
      {children}
      <Text
        variant="body2"
        color={(theme) => theme.palette.text.tertiary}
        sx={mergeSxProps(
          (theme) => ({
            fontWeight: 500,
            lineHeight: 1,
            // This is to make the badge scale down when it's hidden.
            // Emulates the same behavior as MUI's Badge component.
            transition: `${theme.transitions.create(["transform"], {
              duration: theme.transitions.duration.standard,
            })}`,
            transform: isHidden ? "scale(0)" : "scale(1)",
            transformOrigin: "center left",
          }),
          sx
        )}
      >
        {count > max ? `${max}+` : count} {suffix}
      </Text>
    </Stack>
  );
}
