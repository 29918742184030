/* eslint-disable import/max-dependencies */
import { mergeSxProps } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import { SvgIcon, type SvgIconProps } from "@mui/material";
import ATeam from "@src/appV2/lib/assets/illustrations/a_team.svg";
import Break from "@src/appV2/lib/assets/illustrations/break.svg";
import Cancelation from "@src/appV2/lib/assets/illustrations/cancellation.svg";
import Chat from "@src/appV2/lib/assets/illustrations/chat.svg";
import Clock from "@src/appV2/lib/assets/illustrations/clock.svg";
import CompleteProfile from "@src/appV2/lib/assets/illustrations/complete_profile.svg";
import DocumentApproved from "@src/appV2/lib/assets/illustrations/document_approved.svg";
import DocumentRejected from "@src/appV2/lib/assets/illustrations/document_rejected.svg";
import DocumentReview from "@src/appV2/lib/assets/illustrations/document_review.svg";
import DocumentUpload from "@src/appV2/lib/assets/illustrations/document_upload.svg";
import Driving from "@src/appV2/lib/assets/illustrations/driving.svg";
import Facility from "@src/appV2/lib/assets/illustrations/facility.svg";
import Fail from "@src/appV2/lib/assets/illustrations/fail.svg";
import HoursLimit from "@src/appV2/lib/assets/illustrations/hours_limit.svg";
import InstantPay from "@src/appV2/lib/assets/illustrations/instant_pay.svg";
import Location from "@src/appV2/lib/assets/illustrations/location.svg";
import NonInstantPay from "@src/appV2/lib/assets/illustrations/non_instant_pay.svg";
import Pay from "@src/appV2/lib/assets/illustrations/pay.svg";
import QuizInverted from "@src/appV2/lib/assets/illustrations/quiz_inverted.svg";
import QuizRequired from "@src/appV2/lib/assets/illustrations/quiz_required.svg";
import QuizSuccess from "@src/appV2/lib/assets/illustrations/quiz_success.svg";
import RequestSent from "@src/appV2/lib/assets/illustrations/request_sent.svg";
import ReviewSent from "@src/appV2/lib/assets/illustrations/review_sent.svg";
import ScheduledPayment from "@src/appV2/lib/assets/illustrations/scheduled_payment.svg";
import ShiftBooked from "@src/appV2/lib/assets/illustrations/shift_booked.svg";
import Undo from "@src/appV2/lib/assets/illustrations/undo.svg";
import Unlock from "@src/appV2/lib/assets/illustrations/unlock.svg";
import type { SVGProps } from "react";

import { APP_V2_APP_EVENTS } from "../../lib/analytics/events";
import { useLogEffect } from "../../lib/analytics/useLogEffect";

export const ILLUSTRATION_TYPES = [
  "a-team",
  "break",
  "cancelation",
  "chat",
  "clock",
  "complete-profile",
  "document-approved",
  "document-rejected",
  "document-review",
  "document-upload",
  "driving",
  "facility",
  "fail",
  "hours-limit",
  "instant-pay",
  "location",
  "non-instant-pay",
  "pay",
  "quiz-inverted",
  "quiz-required",
  "quiz-success",
  "request-sent",
  "review-sent",
  "scheduled-payment",
  "shift-booked",
  "undo",
  "unlock",
] as const;

export type IllustrationType = (typeof ILLUSTRATION_TYPES)[number];

const illustrationMap: Record<
  IllustrationType,
  { component: React.FunctionComponent<SVGProps<SVGSVGElement>>; width: number; height: number }
> = {
  "a-team": { component: ATeam, width: 176, height: 120 },
  break: { component: Break, width: 176, height: 120 },
  cancelation: { component: Cancelation, width: 176, height: 120 },
  chat: { component: Chat, width: 176, height: 120 },
  clock: { component: Clock, width: 176, height: 120 },
  "complete-profile": { component: CompleteProfile, width: 176, height: 120 },
  "document-approved": { component: DocumentApproved, width: 176, height: 120 },
  "document-rejected": { component: DocumentRejected, width: 176, height: 120 },
  "document-review": { component: DocumentReview, width: 176, height: 120 },
  "document-upload": { component: DocumentUpload, width: 176, height: 120 },
  driving: { component: Driving, width: 176, height: 120 },
  facility: { component: Facility, width: 176, height: 120 },
  fail: { component: Fail, width: 176, height: 120 },
  "hours-limit": { component: HoursLimit, width: 176, height: 120 },
  "instant-pay": { component: InstantPay, width: 176, height: 120 },
  location: { component: Location, width: 176, height: 120 },
  "non-instant-pay": { component: NonInstantPay, width: 176, height: 120 },
  pay: { component: Pay, width: 176, height: 120 },
  "quiz-inverted": { component: QuizInverted, width: 176, height: 120 },
  "quiz-required": { component: QuizRequired, width: 176, height: 120 },
  "quiz-success": { component: QuizSuccess, width: 176, height: 120 },
  "request-sent": { component: RequestSent, width: 176, height: 120 },
  "review-sent": { component: ReviewSent, width: 176, height: 120 },
  "scheduled-payment": { component: ScheduledPayment, width: 176, height: 120 },
  "shift-booked": { component: ShiftBooked, width: 176, height: 120 },
  undo: { component: Undo, width: 176, height: 120 },
  unlock: { component: Unlock, width: 176, height: 120 },
} as const;

export interface IllustrationProps extends SvgIconProps {
  type: IllustrationType;
}

export function Illustration(props: IllustrationProps) {
  const { type, sx, ...restProps } = props;

  const illustrationDefinition = illustrationMap[type];
  const IllustrationComponent = illustrationDefinition.component;

  useLogEffect(
    APP_V2_APP_EVENTS.NON_EXISTING_ILLUSTRATION_REQUESTED,
    {
      type,
    },
    {
      enabled: !isDefined(illustrationDefinition),
    }
  );

  if (!isDefined(illustrationDefinition)) {
    return null;
  }

  return (
    <SvgIcon
      {...restProps}
      inheritViewBox
      sx={mergeSxProps(sx, {
        width: illustrationDefinition.width,
        height: illustrationDefinition.height,
      })}
    >
      <IllustrationComponent />
    </SvgIcon>
  );
}
/* eslint-enable import/max-dependencies */
