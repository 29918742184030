import { Text } from "@clipboard-health/ui-react";
import { Box, Stack, type SxProps } from "@mui/material";

import { CbhIcon, type IconType } from "./CbhIcon";

interface InformationSectionProps {
  iconType: IconType;
  title: string;
  children: React.ReactNode;
  sx?: SxProps;
  id?: string;
}

/**
 * This component is used to display information section with an icon and title.
 * Example usage is to display information about particular workplace policies.
 * Use InformationSectionTextContent for the content of the section, unless something more custom is needed.
 */
export function InformationSection(props: InformationSectionProps) {
  const { iconType, title, children, sx, id } = props;

  return (
    <Box sx={sx} id={id}>
      <Stack direction="row" alignItems="center" spacing={5} sx={{ marginBottom: 5, py: 3 }}>
        <CbhIcon type={iconType} size="medium" />
        <Text semibold variant="h5">
          {title}
        </Text>
      </Stack>

      {children}
    </Box>
  );
}
